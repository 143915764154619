import S from './SocialLinkSet.module.scss';
import { SocialLinkIcon } from 'infrastructure/components';
import { socialLinksData } from 'infrastructure/assets/data';

const SocialLinkSet = () => {
  return (
    <div className={S.socialLinkSet}>
      {socialLinksData.map(({ src, text, link }, index) => (
        <SocialLinkIcon src={src} text={text} key={index} link={link} />
      ))}
    </div>
  );
};

export default SocialLinkSet;
