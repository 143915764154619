import { instagram, email, phoneNumber } from 'infrastructure/assets/img';

const socialLinks = [
  {
    text: 'tractovar',
    link: 'https://instagram.com/tractovar?igshid=YmMyMTA2M2Y=',
    src: instagram,
  },
  {
    text: 'info@tractovar.com',
    link: 'mailto:info@tractovar.com',
    src: email,
  },
  {
    text: '0424-829-7343',
    link: 'https://wa.link/8cgubh',
    src: phoneNumber,
  },
];

export default socialLinks;
