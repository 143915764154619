import S from './ProductCategoryLabel.module.scss';

interface ProductsCategoryLabelProps {
  text?: string;
}

const ProductCategoryLabel = ({ text }: ProductsCategoryLabelProps) => {
  const toText = text ? text : 'Lorem ipsum';

  return <span className={S.ProductCategoryLabel}>{toText}</span>;
};

export default ProductCategoryLabel;
