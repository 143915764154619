import S from './BannerInfo.module.scss';
import { BannerTitle, BannerParagraph } from 'infrastructure/components';
import { bannerText } from 'infrastructure/assets/data';
import { useParams } from 'react-router-dom';

const BannerInfo = () => {
  const { category } = useParams();
  const banner = bannerText.find(({ name }) => name === category);

  return (
    <div className={S.BannerInfo}>
      <BannerTitle text={banner?.title || 'Lorem ipsum dolor'} />
      <BannerParagraph
        text={
          banner?.paragraph ||
          'Lorem ipsum dolor sit amet elit. Iusto, velit maiores. Aliquam, vitae pariatur nesciunt, error reprehenderit odit optio architecto eius quia. Provident libero nesciunt in quis.'
        }
      />
    </div>
  );
};

export default BannerInfo;
