import S from './ProductsTemplate.module.scss';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import { Footer, ProductBanner, ProductCategoryCardSet } from 'infrastructure/components';
import { Cursor } from 'react-creative-cursor';

const ProductsTemplate = () => {
  const { reciever } = useGoToRouteWithAnimation();
  const { category } = useParams();
  const mainRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    reciever();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //scroll to top on url param change
  useEffect(() => {
    const myDiv = mainRef.current;
    if (myDiv) {
      myDiv.scrollIntoView();
    }
  }, [category]);

  return (
    <div className={S.productTemplateCont}>
      <Cursor
        isGelly={true}
        cursorInnerColor={'black'}
        cursorBackgrounColor='#E0B32D'
        cursorSize={18}
      />
      <div className={S.productsTemplate}>
        <main className={S.main} ref={mainRef}>
          <ProductBanner />
          <div className={S.contentCont}>
            {category === undefined ? (
              <>
                <ProductCategoryCardSet />
              </>
            ) : (
              <>
                <Outlet />
                <ProductCategoryCardSet isSmall />
              </>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ProductsTemplate;
