import S from './BannerParagraph.module.scss';

interface BannerParagraphProps {
  text: string;
}

const BannerParagraph = ({ text }: BannerParagraphProps) => {
  return <p className={S.BannerParagraph}>{text}</p>;
};

export default BannerParagraph;
