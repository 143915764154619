import S from './WhoAreWe.module.scss';
import { Description, OwnersImg } from 'infrastructure/components';
import { pair } from 'infrastructure/assets/img';

const WhoAreWe = () => {
  return (
    <section className={S.whoAreWeSection} id='whoAreWe'>
      <div className={S.whoAreWe}>
        <Description />
        <OwnersImg src={pair} />
      </div>
    </section>
  );
};

export default WhoAreWe;
