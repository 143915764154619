import { chat, security, time } from 'infrastructure/assets/img';

interface FAQCardProps {
  id: number;
  img: string;
  alt: string;
  size: 'normal' | 'large';
  title: string[];
  greyText?: boolean;
}

const FAQ: FAQCardProps[] = [
  {
    id: 1,
    img: time,
    alt: 'time',
    size: 'normal',
    title: ['Tiempo de espera', 'X:XX Hrs'],
    greyText: true,
  },
  {
    id: 2,
    img: chat,
    alt: 'chat',
    size: 'large',
    title: ['¡Resuelve tus dudas con soporte técnico!'],
  },
  {
    id: 3,
    img: security,
    alt: 'security',
    size: 'normal',
    title: ['¡Tu información', 'es totalmente privada!'],
    greyText: true,
  },
];

export default FAQ;
