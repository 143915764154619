import S from './OwnersImg.module.scss';

interface OwnersImgProps {
  src: string;
}

const OwnersImg = ({ src }: OwnersImgProps) => {
  return <img className={S.mainImg} src={src} alt='Owner' />;
};

export default OwnersImg;
