import S from './Footer.module.scss';
import { Navbar, SocialLinkSet } from 'infrastructure/components';

const Footer = () => {
  return (
    <footer className={S.footer}>
      <div className={S.footer__innerCont}>
        <div className={S.footer__leftContainer}>
          <p>
            Calle Principal, C.C. Reservenca,
            <br /> PB, Local 3-A, Los Mesones, <br /> Barcelona, Anzoátegui
          </p>
          <p>info@tractovar.com</p>
          <p>
            <span>&#169;</span> IKUSA MEDIA C.A. todos los Derechos Reservados
          </p>
        </div>
        <SocialLinkSet />
        <Navbar inFooter />
      </div>
    </footer>
  );
};

export default Footer;
