export interface CI {
  ROUTER_ANIMATION: string;
  REDIRECT: string;
}

const C: CI = {
  ROUTER_ANIMATION: 'ROUTER_ANIMATION',
  REDIRECT: 'REDIRECT',
};

export default C;
