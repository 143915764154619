import S from './ProductCategoryImg.module.scss';

interface productCategoryImgProps {
  img?: string;
  alt?: string;
}

const ProductCategoryImg = ({ img, alt }: productCategoryImgProps) => {
  const toImgSrc = img ? img : 'https://via.placeholder.com/150';
  const toImgAlt = alt ? alt : 'Product Category Image';

  return <img className={S.productCategoryImg} src={toImgSrc} alt={toImgAlt} />;
};

export default ProductCategoryImg;
