import { slider1, slider2, slider3, slider4 } from 'infrastructure/assets/img';

const slider = [
  {
    id: 1,
    img: slider1,
    title: 'slider image 1',
  },
  {
    id: 2,
    img: slider2,
    title: 'slider image 2',
  },
  {
    id: 3,
    img: slider3,
    title: 'slider image 3',
  },
  {
    id: 4,
    img: slider4,
    title: 'slider image 4',
  },
];

export default slider;
