import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

type products = {
  id: number;
  name: string;
  categoryName: string;
  to: string;
  img: string;
  text: string;
  icon: string;
};

interface useFilterProductsByCategory {
  productsData: products[];
}

const useFilterProductByCategory = ({ productsData }: useFilterProductsByCategory) => {
  const { category } = useParams();
  const [products, setProducts] = useState<products[]>(productsData);

  useEffect(() => {
    if (category === undefined) {
      return;
    } else {
      const productsFiltered = productsData.filter((product) => product.categoryName !== category);
      setProducts(productsFiltered);
    }
  }, [category, productsData]);

  return { products };
};

export default useFilterProductByCategory;
