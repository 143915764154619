import { FormErrorsProps } from '../interfaces/interfaces';

const validation = ({ name, lastName, email, message }: FormErrorsProps) => {
  const errors = {
    name: '',
    lastName: '',
    contactNumber: '',
    email: '',
    message: '',
  };

  //Validation for name
  if (!name.trim()) {
    errors.name = 'Este campo es requerido';
  } else if (name.trim() && (name.trim().length < 3 || name.trim().length > 16)) {
    errors.name = 'Este campo solo acepta de 3 a 16 caracteres!';
  } else if (name.trim() && !/^[a-zA-Z ]*$/.test(name.trim())) {
    errors.name = 'Este campo no acepta numeros o caracteres especiales!';
  } else {
    errors.name = '';
  }

  //Validation for lastName
  if (!lastName.trim()) {
    errors.lastName = 'Este campo es requerido';
  } else if (lastName.trim() && (lastName.trim().length < 3 || lastName.trim().length > 16)) {
    errors.lastName = 'Este campo solo acepta de 3 a 16 caracteres!';
  } else if (lastName.trim() && !/^[a-zA-Z ]*$/.test(lastName.trim())) {
    errors.lastName = 'Este campo no acepta numeros o caracteres especiales!';
  } else {
    errors.lastName = '';
  }

  //Validation for email
  if (!email.trim()) {
    errors.email = 'Este campo es requerido';
  } else if (
    email.trim() &&
    !email
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.email = 'Por favor introduzca un email valido';
  } else {
    errors.email = '';
  }

  //Validation for message
  if (!message.trim()) {
    errors.message = 'Este campo es requerido';
  } else if (message.trim() && message.trim().length < 10) {
    errors.message = 'El campo mensaje debe ser minimo 10 caracteres!';
  } else {
    errors.message = '';
  }

  return errors;
};

export default validation;
