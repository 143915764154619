import S from './FAQ.module.scss';
import { FAQCardSet, RoundButton } from 'infrastructure/components';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';

const Support = () => {
  const { redirecting } = useGoToRouteWithAnimation();
  return (
    <section className={S.FAQ} id='support'>
      <h2 className={S.FAQ__title}>PORTAL DE ATENCIÓN AL USUARIO</h2>
      <FAQCardSet />
      <div className={S.FAQ__btnCont}>
        <div onClick={redirecting('/support', { from: '/', to: 'faq' })}>
          <RoundButton text='HACER UNA PREGUNTA' small dark />
        </div>
      </div>
    </section>
  );
};

export default Support;
