import S from './Drawer.module.scss';
import { ProductsDropdownButton, NavLink, SocialLinkIcon } from 'infrastructure/components';
import { socialLinksData } from 'infrastructure/assets/data';
import { useState } from 'react';

interface DrawerProps {
  outHome?: boolean;
}

const Drawer = ({ outHome }: DrawerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  let show = isOpen ? `${S.drawer__show}` : `${S.drawer__hide}`;
  let showFocus = isOpen ? `${S.drawer__showFocus}` : `${S.drawer__hideFocus}`;

  return (
    <div tabIndex={-1} className={S.drawer}>
      <ProductsDropdownButton onClick={() => setIsOpen(!isOpen)} />

      <nav className={`${S.drawer__navbar} ${show}`}>
        <ul className={`${S.drawer__navList}`}>
          <div className={S.drawer__itemCont} onClick={() => setIsOpen(false)}>
            <NavLink
              href={outHome ? '/#slider' : '#slider'}
              text='INICIO'
              routing={outHome && true}
            />
          </div>
          <div className={S.drawer__itemCont} onClick={() => setIsOpen(false)}>
            <NavLink
              href={outHome ? '/#whoAreWe' : '#whoAreWe'}
              text='QUIENES SOMOS'
              routing={outHome && true}
            />
          </div>
          <div className={S.drawer__itemCont} onClick={() => setIsOpen(false)}>
            <NavLink
              href={outHome ? '/#industries' : '#industries'}
              text='INDUSTRIAS'
              routing={outHome && true}
            />
          </div>
          <div className={S.drawer__itemCont} onClick={() => setIsOpen(false)}>
            <NavLink
              href={outHome ? '/#products' : '#products'}
              text='PRODUCTOS'
              routing={outHome && true}
            />
          </div>
          <div className={S.drawer__itemCont} onClick={() => setIsOpen(false)}>
            <NavLink
              href={outHome ? '/#support' : '#support'}
              text='SOPORTE'
              routing={outHome && true}
            />
          </div>
          <div className={S.drawer__itemCont} onClick={() => setIsOpen(false)}>
            <NavLink
              href={outHome ? '/#contact' : '#contact'}
              text='CONTÁCTANOS'
              routing={outHome && true}
            />
          </div>
        </ul>
        <div className={S.drawer__linksCont}>
          {socialLinksData.map(({ src, text, link }, index) => (
            <SocialLinkIcon src={src} key={index} link={link} isInDrawer />
          ))}
        </div>
      </nav>
      <div className={`${S.drawer__focus} ${showFocus}`} onClick={() => setIsOpen(false)} />
    </div>
  );
};

export default Drawer;
