import {
  bannerProducts,
  motorCategory,
  inyeccionCategory,
  juegosDeEmpacaduraCategory,
  partesElectricasCategory,
  indicadoresCategory,
} from 'infrastructure/assets/img';

interface BannerImgProps {
  id: string;
  name: string | undefined;
  img: string;
}

const bannerImg: BannerImgProps[] = [
  {
    id: 'bannerImg' + 1,
    name: undefined,
    img: bannerProducts,
  },
  {
    id: 'bannerImg' + 2,
    name: 'componentesDeMotor',
    img: motorCategory,
  },
  {
    id: 'bannerImg' + 3,
    name: 'juegosDeEmpacadura',
    img: juegosDeEmpacaduraCategory,
  },
  {
    id: 'bannerImg' + 4,
    name: 'partesElectricas',
    img: partesElectricasCategory,
  },
  {
    id: 'bannerImg' + 5,
    name: 'indicadores',
    img: indicadoresCategory,
  },
  {
    id: 'bannerImg' + 6,
    name: 'sistemaDeInyeccion',
    img: inyeccionCategory,
  },
];

export default bannerImg;
