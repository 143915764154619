import S from './ProductCategoryCard.module.scss';
import {
  ProductCategoryImg,
  ProductCategoryLabel,
  ProductCategoryText,
} from 'infrastructure/components';
import { Link } from 'react-router-dom';

interface ProductCategoryCardProps {
  img?: string;
  alt?: string;
  label?: string;
  text?: string;
  isSmall?: boolean;
  to: string;
  isALink?: boolean;
  onClick?: () => void;
}

const ProductCategoryCard = ({
  img,
  alt,
  label,
  text,
  isSmall,
  to,
  isALink,
  onClick,
}: ProductCategoryCardProps) => {
  const classSmall = isSmall ? S.small : '';

  return isALink ? (
    <Link to={to} className={`${S.ProductCategoryCard}  ${classSmall}`}>
      <ProductCategoryImg img={img} alt={alt} />
      <ProductCategoryLabel text={label} />
      <ProductCategoryText text={`${text?.substring(0, 85)}...`} />
    </Link>
  ) : (
    <div className={`${S.ProductCategoryCard}  ${classSmall}`} onClick={onClick}>
      <ProductCategoryImg img={img} alt={alt} />
      <ProductCategoryLabel text={label} />
      <ProductCategoryText text={`${text?.substring(0, 85)}...`} />
    </div>
  );
};

export default ProductCategoryCard;
