import { FAQImg } from 'infrastructure/components';
import S from './FAQCard.module.scss';

interface FAQCardProps {
  src: string;
  alt: string;
  size: 'normal' | 'large';
  title: string | string[];
  greyText?: boolean;
}
const FAQCard = ({ src, alt, size, title, greyText }: FAQCardProps) => {
  let grey = greyText ? S.grey : '';
  let textSize = size === 'normal' ? S.normal : S.large;

  return (
    <div className={S.FAQCard}>
      <FAQImg src={src} alt={alt} size={size} />
      <h3 className={` ${S.FAQCard__text} ${grey} ${textSize}`}>
        {title[0]} <br /> {title[1]}
      </h3>
    </div>
  );
};

export default FAQCard;
