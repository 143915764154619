import { useLocation } from 'react-router-dom';
import S from './PageLogo.module.scss';

interface PageLogoProps {
  src: string;
  alt: string;
}

const PageLogo = ({ src, alt }: PageLogoProps) => {
  const location = useLocation();

  return (
    <a href={location.pathname === '/' ? '#slider' : '/#slider'} className={S.pageLogoCont}>
      <img className={S.pageLogo} src={src} alt={alt} />
    </a>
  );
};

export default PageLogo;
