import S from './FAQTemplate.module.scss';
import { FAQSlider } from 'infrastructure/components';
import { FAQSliderData } from 'infrastructure/assets/data';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import { useLayoutEffect } from 'react';
import { Cursor } from 'react-creative-cursor';

const FAQTemplate = () => {
  const { reciever } = useGoToRouteWithAnimation();

  useLayoutEffect(() => {
    reciever();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={S.FAQTemplate}>
      <Cursor
        isGelly={true}
        cursorInnerColor={'black'}
        cursorBackgrounColor='#E0B32D'
        cursorSize={18}
      />
      <FAQSlider images={FAQSliderData} />
    </div>
  );
};

export default FAQTemplate;
