import S from './SliderImgSet.module.scss';
import { SliderImg } from 'infrastructure/components';

interface SliderImgSetProps {
  images: {
    id: number;
    img: string;
    title: string;
  }[];
  activeIndex: number;
  isInFAQ?: boolean;
}

const SliderImgSet = ({ images, activeIndex, isInFAQ }: SliderImgSetProps) => {
  return (
    <>
      {images.map((image, index) => {
        const { img, title } = image;
        let position =
          index === activeIndex
            ? isInFAQ
              ? `${S.inFAQActive}`
              : `${S.active}`
            : index === activeIndex - 1 || (activeIndex === 0 && index === images.length - 1)
            ? isInFAQ
              ? `${S.inFAQLeft}`
              : `${S.left}`
            : isInFAQ
            ? `${S.inFAQRight}`
            : `${S.right}`;
        return (
          <SliderImg key={index} img={img} title={title} position={position} isInFAQ={isInFAQ} />
        );
      })}
    </>
  );
};

export default SliderImgSet;
