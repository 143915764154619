import S from './ProductsNavLink.module.scss';

interface ProductsNavLinkProps {
  src: string;
  alt: string;
  isLogoBlue?: boolean;
  isBgBlue?: boolean;
  haveCircle?: boolean;
  smallLogo?: boolean;
  withHover?: boolean;
}

const ProductsNavlink = ({
  src,
  alt,
  haveCircle,
  isBgBlue,
  isLogoBlue,
  smallLogo,
  withHover,
}: ProductsNavLinkProps) => {
  let bg = isBgBlue ? `${S.blueBg}` : '';
  let blueLogo = isLogoBlue ? `${S.blueLogo}` : '';
  let isSmallLogo = smallLogo ? `${S.smallLogo}` : '';
  let hover = withHover ? `${S.hoverEffect}` : '';

  return (
    <>
      {haveCircle ? (
        <div className={`${S.productsNavlink__circle} ${hover}`}>
          <img
            className={`${S.productsNavlink__circle__img}  ${blueLogo} ${isSmallLogo}`}
            src={src}
            alt={alt}
          />
        </div>
      ) : (
        <div className={`${S.productsNavlink} ${bg}`}>
          <img className={`${S.productsNavlink__img} ${isSmallLogo}`} src={src} alt={alt} />
        </div>
      )}
    </>
  );
};

export default ProductsNavlink;
