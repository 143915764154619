import S from './Industries.module.scss';
import { IndustriesCard, Subtitle } from 'infrastructure/components';
import { industriesData } from 'infrastructure/assets/data';

const Industries = () => {
  return (
    <section className={S.industries} id='industries'>
      <Subtitle
        title='Intereses a nivel de industrias'
        align='center'
        lineColor='yellow'
        textColor='blue'
        fontWeight='normal'
      />
      <div className={S.interestsContainer}>
        {industriesData.map((item, index) => {
          return (
            <IndustriesCard
              key={index}
              src={item.img}
              alt={item.title}
              title={item.title}
              text={item.description}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Industries;
