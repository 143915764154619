import S from './Form.module.scss';
import { useWindowSize } from 'infrastructure/hooks';
import { Subtitle, FormInput, RoundButton, FormTextArea } from 'infrastructure/components';
import { useForm } from './hooks';

const Form = () => {
  const { values, errors, disableSubmit, isValidating, handleSubmit, handleChange } = useForm(
    {
      name: '',
      lastName: '',
      email: '',
      contactNumber: '',
      message: '',
    },
    {
      name: '',
      lastName: '',
      email: '',

      message: '',
    }
  );
  const { width } = useWindowSize();
  const isMobile = width <= 599;

  return (
    <form className={S.form} onSubmit={handleSubmit}>
      <Subtitle
        title='CONTACTA CON NOSOTROS'
        textColor='white'
        lineColor='yellow'
        align={isMobile ? 'center' : 'left'}
        fontWeight='light'
        textSize='small'
      />
      <FormInput
        id='form1-name'
        name='name'
        type='text'
        label='NOMBRE'
        value={values.name}
        errorMessage={errors.name}
        onChange={handleChange}
        isValidating={isValidating}
        required
      />
      <FormInput
        id='form1-lastName'
        name='lastName'
        type='text'
        label='APELLIDO'
        value={values.lastName}
        errorMessage={errors.lastName}
        onChange={handleChange}
        isValidating={isValidating}
        required
      />
      <FormInput
        id='form1-email'
        name='email'
        type='email'
        label='CORREO'
        value={values.email}
        errorMessage={errors.email}
        onChange={handleChange}
        isValidating={isValidating}
        required
      />
      <FormInput
        id='form1-contactNumber'
        name='contactNumber'
        type='number'
        label={[
          <span key='sp1'>NÚMERO DE CONTACTO</span>,
          <span style={{ margin: '0 0 0 5px' }} key='sp2'>
            (OPCIONAL)
          </span>,
        ]}
        value={values.contactNumber === null ? '' : values.contactNumber}
        onChange={handleChange}
      />
      <FormTextArea
        id='form1-message'
        name='message'
        label='MENSAJE'
        value={values.message}
        errorMessage={errors.message}
        isValidating={isValidating}
        onChange={handleChange}
        required
      />
      <RoundButton text='ENVIAR' small disabled={disableSubmit} />
    </form>
  );
};

export default Form;
