import { useEffect, useState } from 'react';
import S from './FormInput.module.scss';

interface IFormInput {
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  required?: boolean;
  label: string | [JSX.Element, JSX.Element];
  value: string | number;
  errorMessage?: string;
  isLabelSmall?: boolean;
  isInFAQ?: boolean;
  resetFocus?: boolean;
  isValidating?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput = (props: IFormInput) => {
  const { label, errorMessage, isLabelSmall, isInFAQ, isValidating, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
  };
  const handleFocusedFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    inputProps.name === 'confirmPassword' && setFocused(true);
  };

  useEffect(() => {
    if (!isValidating) {
      setFocused(false);
    }
  }, [isValidating]);

  return (
    <div className={`${S.formInput} ${isInFAQ && S.FAQ}`}>
      <label className={`${S.formInput__label} ${isLabelSmall && S.smallLabel}`}>{label}</label>
      <input
        className={`${S.formInput__input}`}
        {...inputProps}
        onBlur={handleFocusedBlur}
        onFocus={handleFocusedFocus}
        data-focused={errorMessage && focused}
      />

      <span className={`${S.formInput__span}`}>{errorMessage}</span>
    </div>
  );
};

export default FormInput;
