import S from './CardImg.module.scss';

interface CardImgProps {
  src: string;
  alt: string;
}

const CardImg = ({ src, alt }: CardImgProps) => {
  return (
    <div className={S.imgContainer}>
      <img src={src} alt={alt} className={S.imgContainer__img} />
    </div>
  );
};

export default CardImg;
