import S from './FAQbeginBtn.module.scss';

interface FAQbeginBtnProps {
  onClick?: () => void;
  text: string;
}

const FAQbeginBtn = ({ onClick, text }: FAQbeginBtnProps) => {
  return (
    <button className={S.faqBeginBtn} onClick={onClick}>
      {text}
    </button>
  );
};

export default FAQbeginBtn;
