import { BrowserRouter as Router } from 'react-router-dom';
import * as Screen from '../screens';
import AppAllRoutes from './AppAllRoutes';

const AppRouters = () => {
  return (
    <Router>
      <Screen.Header />
      <AppAllRoutes />
    </Router>
  );
};

export default AppRouters;
