import S from './ProductCategoryText.module.scss';

interface ProductCategoryTextProps {
  text?: string;
}

const ProductCategoryText = ({ text }: ProductCategoryTextProps) => {
  return <p className={S.ProducCategoryText}>{text}</p>;
};

export default ProductCategoryText;
