import S from './FAQSlider.module.scss';
import { FAQForm } from 'infrastructure/components';
import { useEffect, useState } from 'react';
import { goBack } from 'infrastructure/assets/img';
import {
  SliderImgSet,
  SliderButtonsSet,
  ProductsNavLink,
  FAQbeginBtn,
} from 'infrastructure/components';

interface SliderProps {
  images: {
    id: number;
    img: string;
    title: string;
  }[];
}

const FAQSlider = ({ images }: SliderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const last = images.length - 1;

  const slideToForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    if (activeIndex < 0) {
      setActiveIndex(last);
    }
    if (activeIndex > last) {
      setActiveIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, activeIndex]);

  return (
    <section className={`${S.FAQSlider}`}>
      <div className={`${S.FAQSlider__ContentCont} ${showForm && S.newHeight}`}>
        <div className={`${S.FAQSlider__preview} ${showForm && S.previewLeft}`}>
          <div className={S.FAQSlider__SliderCont}>
            <SliderImgSet images={images} activeIndex={activeIndex} isInFAQ />
          </div>
          <SliderButtonsSet
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isInFAQ
          />
        </div>

        <div className={`${S.FAQSlider__form} ${showForm && S.formLeft}`}>
          <FAQForm />
        </div>
      </div>
      {activeIndex === last ? (
        <div
          tabIndex={showForm ? -1 : undefined}
          className={`${S.FAQSlider__toForm} ${showForm && S.fadeOut}`}
          onClick={slideToForm}
        >
          <FAQbeginBtn text='COMENZAR' />
        </div>
      ) : (
        <div className={S.FAQSlider__next} onClick={() => setActiveIndex((prev) => prev + 1)}>
          <ProductsNavLink src={goBack} haveCircle alt='next slide' />
        </div>
      )}
    </section>
  );
};

export default FAQSlider;
