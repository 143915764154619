import { useGlobalContext } from 'context';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useGoToRouteWithAnimation = () => {
  const {
    dispatch,
    C,
    toLocalStorage: { redirect, routerAnimation },
  } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [redirectionData, setRedirectionData] = useState<{
    to: string;
    state: { from: string; to: string } | undefined;
  }>({
    to: '',
    state: { from: '', to: '' },
  });

  const redirecting = (to: string, state?: { from: string; to: string }) => () => {
    dispatch({ type: C.ROUTER_ANIMATION, payload: { fadeIn: true, fadeOut: false } });

    setRedirectionData({
      to,
      state,
    });
  };

  const reciever = () => {
    dispatch({ type: C.ROUTER_ANIMATION, payload: { fadeIn: false, fadeOut: false } });

    setTimeout(() => {
      dispatch({ type: C.ROUTER_ANIMATION, payload: { fadeIn: false, fadeOut: true } });
      dispatch({ type: C.REDIRECT, payload: false });
    }, 1000);
  };

  const goingBack = () => {
    if (/\/products$/.test(location.pathname)) {
      dispatch({ type: C.ROUTER_ANIMATION, payload: { fadeIn: true, fadeOut: false } });

      setRedirectionData({
        to: '/',
        state: { from: '/products', to: '/' },
      });
    } else if (/\/products\/[A-Za-z0-9]{1,}$/.test(location.pathname)) {
      navigate('/products');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (redirect && routerAnimation.fadeIn && redirectionData.to) {
      navigate(redirectionData.to, { state: redirectionData.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  return { redirecting, reciever, goingBack };
};

export default useGoToRouteWithAnimation;
