import { productsData } from 'infrastructure/assets/data';
import { ProductCategoryCard, ProductCategoryTitle } from 'infrastructure/components';
import { useFilterProductByCategory } from './ProductCategoryCardSetHooks';
import { Fade } from 'react-awesome-reveal';

import S from './ProductCategoryCardSet.module.scss';

interface ProductCategoryCardSetProps {
  isSmall?: boolean;
}

const ProductCategoryCardSet = ({ isSmall }: ProductCategoryCardSetProps) => {
  const { products } = useFilterProductByCategory({ productsData });

  return (
    <>
      {isSmall ? null : <ProductCategoryTitle text='Categorías' />}
      <div className={S.productCategoryCardSet}>
        <Fade
          cascade
          damping={0.2}
          delay={100}
          triggerOnce
          style={{ margin: '0 20px', width: isSmall ? 'min(100%, 290px)' : 'min(100%, 400px)' }}
        >
          {products.map(({ name, to, img, text }, index) => (
            <ProductCategoryCard
              isALink
              key={`${name}-${index}`}
              to={to}
              isSmall={isSmall}
              img={img}
              alt={name}
              label={name}
              text={text}
            />
          ))}
        </Fade>
      </div>
    </>
  );
};

export default ProductCategoryCardSet;
