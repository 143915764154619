import S from './ProductsDrawerButton.module.scss';
import { GiHamburgerMenu } from 'react-icons/gi';

interface ProductsDropdownButtonProps {
  onClick: () => void;
}

const ProductsDrawerButton = ({ onClick }: ProductsDropdownButtonProps) => {
  return <GiHamburgerMenu onClick={onClick} className={S.productsDrawerButton} />;
};

export default ProductsDrawerButton;
