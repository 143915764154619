import { useFilterProductByCategory, useGetInTouchAlert } from './ProductSubCategoryCardSetHooks';
import { productsCategoryData } from 'infrastructure/assets/data';
import S from './ProductSubCategoryCardSet.module.scss';
import {
  ProductCategoryCard,
  ProductCategoryTitle,
  ViewProductsButton,
} from 'infrastructure/components';
import { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useParams } from 'react-router-dom';

const ProductSubCategoryCardSet = () => {
  const { categoryName, subCategoryList } = useFilterProductByCategory({ productsCategoryData });
  const { fireAlert } = useGetInTouchAlert();
  const [shrink, setShrink] = useState(false);
  const { category } = useParams();

  //set shrink to false when params change
  useEffect(() => {
    setShrink(false);
  }, [category]);

  return (
    <>
      <ProductCategoryTitle text={categoryName ? categoryName : 'Esta sub-categoria no existe'} />
      <div className={S.delimiter} />
      <div className={`${S.productSubCategoryCardSet} ${shrink && S.shrink}`}>
        <Fade
          cascade
          damping={0.2}
          delay={100}
          triggerOnce
          style={{ width: 'min(100%, 320px)' }}
          className={S.fadeCont}
        >
          {subCategoryList.map(({ name, img, text }, index) => (
            <ProductCategoryCard
              onClick={() =>
                fireAlert({ categoryName, subCategoryName: name, infoText: text, img })
              }
              key={`${name}-${index}`}
              to='#'
              img={img}
              alt={name}
              label={name}
              text={text}
            />
          ))}
        </Fade>
      </div>
      <div className={S.delimiter} />
      {subCategoryList.length > 4 && (
        <ViewProductsButton
          onClick={() => setShrink(!shrink)}
          text={shrink ? 'Ver menos' : 'Ver más'}
        />
      )}
    </>
  );
};

export default ProductSubCategoryCardSet;
