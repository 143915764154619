import S from './FAQCardSet.module.scss';
import { faqData } from 'infrastructure/assets/data';
import { FAQCard } from 'infrastructure/components';

const FAQCardSet = () => {
  return (
    <div className={S.FAQCardSet}>
      {faqData.map((faq, index) => {
        return (
          <FAQCard
            src={faq.img}
            alt={faq.alt}
            size={faq.size}
            title={faq.title}
            key={index}
            greyText={faq.greyText}
          />
        );
      })}
    </div>
  );
};

export default FAQCardSet;
