import S from './SliderImg.module.scss';

interface SliderImgProps {
  img: string;
  title: string;
  position: string;
  isInFAQ?: boolean;
}

const SliderImg = ({ img, title, position, isInFAQ }: SliderImgProps) => {
  let faqClass = isInFAQ ? `${S.faq}` : '';

  return (
    <>
      {isInFAQ ? (
        <div className={`${faqClass} ${position}`}>
          <img src={img} alt={title} className={S.faq__img} />
          <h2 className={S.faq__text}>{title}</h2>
        </div>
      ) : (
        <img src={img} alt={title} className={`${S.sliderImg} ${position}`} />
      )}
    </>
  );
};

export default SliderImg;
