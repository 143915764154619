interface BannerImgProps {
  id: string;
  name: string | undefined;
  title: string | undefined;
  paragraph: string;
}

const bannerImg: BannerImgProps[] = [
  {
    id: 'bannerText' + 1,
    name: undefined,
    title: '¡El repuesto que busques lo tenemos!',
    paragraph:
      'Y si no lo tenemos en nuestro stock, lo traemos para ti a través de importación marítima o aérea.',
  },
  {
    id: 'bannerText' + 2,
    name: 'componentesDeMotor',
    title: 'Componentes de motor',
    paragraph:
      'Son los diferentes dispositivos que al recibir una señal, proceden a efectuar cualquier operación para lo que fueron diseñados',
  },
  {
    id: 'bannerText' + 3,
    name: 'juegosDeEmpacadura',
    title: 'Juegos de empacaduras',
    paragraph:
      'Son los diferentes dispositivos que al recibir una señal, proceden a efectuar cualquier operación para lo que fueron diseñados.',
  },
  {
    id: 'bannerText' + 4,
    name: 'partesElectricas',
    title: 'Partes eléctricas',
    paragraph:
      'El sistema eléctrico de un tractor o de un equipo automotor, es un conjunto de dispositivos relacionados entre sí, es el responsable del arranque del motor para poner en marcha la maquinaria.',
  },
  {
    id: 'bannerText' + 5,
    name: 'indicadores',
    title: 'Indicadores',
    paragraph:
      'Piezas el cual su uso principalmente se utilizan para medir los diferentes componentes y estados de la maquinaria.',
  },
  {
    id: 'bannerText' + 6,
    name: 'sistemaDeInyeccion',
    title: 'Sistema de inyección:',
    paragraph:
      'Los sistemas de inyección son los mecanismo de alimentación de los motores de la maquinaria motores de combustión interna, en los que el carburador ha sido sustituido por los inyectores. En coches diésel es obligatorio, porque el combustible se inyecta en la cámara en el mismo momento de la combustión.',
  },
];

export default bannerImg;
