import S from './FAQImg.module.scss';

interface FAQImgProps {
  src: string;
  alt: string;
  size: 'normal' | 'large';
}

const FAQImg = ({ src, alt, size }: FAQImgProps) => {
  let imgSize = size === 'large' ? S.Large : S.Normal;
  return <img src={src} alt={alt} className={`${imgSize}`} />;
};

export default FAQImg;
