import S from './MainHeader.module.scss';
import { PageLogo, Navbar, Drawer } from 'infrastructure/components';
import { mainLogo } from 'infrastructure/assets/img';

interface MainHeaderProps {
  outHome?: boolean;
}

const Header = ({ outHome }: MainHeaderProps) => {
  return (
    <header className={S.mainHeader}>
      <div className={`${S.mainHeader__innerCont}`}>
        <PageLogo src={mainLogo} alt='Logo Tractovar' />
        <Navbar outHome={outHome} />
        <Drawer outHome={outHome} />
      </div>
    </header>
  );
};

export default Header;
