import S from './AnimationRouteIcon.module.scss';
import { mainLogoWhite } from 'infrastructure/assets/img';
import { useGlobalContext } from 'context';
import { useState } from 'react';

const AnimationRouteIcon = () => {
  const { dispatch, C } = useGlobalContext();
  const [isLoaded, setIsloaded] = useState({
    bar: false,
    logo: false,
  });

  const redirecting = () => {
    dispatch({ type: C.REDIRECT, payload: true });
  };

  const showAnimation = (type: 'bar' | 'logo') => () => {
    setIsloaded({ ...isLoaded, [type]: true });
  };

  return (
    <div className={S.flexWrapper}>
      <div className={S.singleChart}>
        <svg viewBox='0 0 36 36' className={`${S.circularChart} ${S.orange}`}>
          <path
            className={S.circleBg}
            d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
          />
          {isLoaded.bar && (
            <path
              className={S.circle}
              onAnimationEnd={redirecting}
              strokeDasharray='100, 100'
              d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
            />
          )}
        </svg>
        <div className={S.insideCircle}>
          <img
            loading='lazy'
            className={`${S.logo} ${isLoaded.logo && S.logoAnimation}`}
            onAnimationEnd={showAnimation('bar')}
            onLoad={showAnimation('logo')}
            src={mainLogoWhite}
            alt='routeLoader'
          />
        </div>
      </div>
    </div>
  );
};

export default AnimationRouteIcon;
