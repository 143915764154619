import S from './PageTitle.module.scss';

interface PageTitleProps {
  title: string;
}

const PageTitle = ({ title }: PageTitleProps) => {
  return <h1 className={S.pageTitle}>{title}</h1>;
};

export default PageTitle;
