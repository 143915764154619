import {
  kitCamisaAnilloPistonSubCat,
  camaraDeMotorSubCat,
  ciguenalSubCat,
  empacaduraDeMotorSubCat,
  empacaduraDeCamaraSubCat,
  empacaduraDeConvertidorSubCat,
  empacaduraDeTransmisionSubCat,
  bocinaDeBielaSubCat,
  bocinaDeLevaSubCat,
  alarmaDeRetroceso,
  sensores,
  motorDeArranque,
  faroDeIluminacion,
  alternadores,
  solenoidesSubCat,
  interruptoresSubCat,
  indicadorPresionDeAire,
  indicadorPresionDeCombustible,
  indicadorTemperaturaDeAgua,
  indicadorTemperaturaDeConvertidor,
  toberas,
  toberaTipoLapiz,
  bombantes,
  tuboDeInyeccion,
  cojinetesDeBielaYBancadaSubCat,
} from 'infrastructure/assets/img';

const productsCategory = [
  {
    name: 'COMPONENTES DE MOTOR',
    categoryName: 'componentesDeMotor',
    categoryList: [
      {
        id: 'KIT CAMISA  ANILLO  PISTON',
        img: kitCamisaAnilloPistonSubCat,
        name: 'KIT CAMISA  ANILLO  PISTÓN',
        text: 'Los pistones, las  camisas y los anillos son elementos básicos de un ajuste de motor. Vienen por separado o en conjunto. Existe el kit de cilindros (incluye un pistón + juego de anillos + camisa) y kit de pistón (incluye pistón + juego de anillos).',
      },
      {
        id: 'COJINETES DE BIELA Y BANCADA',
        img: cojinetesDeBielaYBancadaSubCat,
        name: 'COJINETES DE BIELA Y BANCADA',
        text: 'Son piezas fundamentales en el motor, puesto que sin ellas no podría funcionar. Los cojinetes permiten que la biela pueda girar libremente en el cigüeñal, y al mismo tiempo también hace posible que el cigüeñal gire con la mínima resistencia sobre el bloque motor.',
      },
      {
        id: 'BOCINA DE LEVA',
        img: bocinaDeLevaSubCat,
        name: 'BOCINA DE LEVA',
        text: 'Es uno de los ejes principales del motor de explosión de cuatro tiempos. Controla los momentos de apertura y cierre de las válvulas ya sea por medios de órganos intermediarios o directamente sobre las válvulas.',
      },
      {
        id: 'BOCINAS DE BIELA',
        img: bocinaDeBielaSubCat,
        name: 'BOCINAS DE BIELA',
        text: 'Las bocinas de biela son piezas utilizadas para transmitir longitudinalmente movimiento entre dos partes de un mecanismo.',
      },
      {
        id: 'CAMARA',
        img: camaraDeMotorSubCat,
        name: 'CÁMARA',
        text: 'La cámara es el lugar donde se realiza la combustión del combustible con el comburente, generalmente aire, en el motor de combustión interna',
      },
      {
        id: 'CIGUEÑALES',
        img: ciguenalSubCat,
        name: 'CIGUEÑAL',
        text: 'Su función principal es transformar el movimiento lineal de los pistones en un movimiento circular. El cigüeñal está unido a las bielas y estas a su vez a los pistones que se mueven por el proceso de combustión. También recibe otros nombres como cigüeñal, árbol de manivelas o eje motor.',
      },
    ],
  },

  {
    name: 'JUEGOS DE EMPACADURA',
    categoryName: 'juegosDeEmpacadura',
    categoryList: [
      {
        id: 'JUEGO DE EMPACADURAS DE MOTOR',
        img: empacaduraDeMotorSubCat,
        name: 'JUEGO DE EMPACADURAS DE MOTOR',
        text: 'Se encarga de sellar los diferentes componentes y tapas del motor para evitar filtración de los fluidos.',
      },
      {
        id: 'JUEGO DE EMPACADURAS PARA TRANSMISION',
        img: empacaduraDeTransmisionSubCat,
        name: 'JUEGO DE EMPACADURAS PARA TRANSMISIÓN',
        text: 'Estas empacaduras son de un material deformable que permite crear un sello estático para mantener dicho sello bajo las condiciones operativas.',
      },
      {
        id: 'JUEGO DE EMPACADURA DE CONVERTIDOR',
        img: empacaduraDeConvertidorSubCat,
        name: 'JUEGO DE EMPACADURA DE CONVERTIDOR',
        text: 'Sirve para sellar bien la unión de las caras mecanizadas de los elementos de cierre y ayuda a su acoplamiento, ya que corrige los posibles fallos que existan en el mecanizado.',
      },
      {
        id: 'EMPACADURAS DE CAMARA (DESCARBONIZAR)',
        img: empacaduraDeCamaraSubCat,
        name: 'EMPACADURAS DE CÁMARA (DESCARBONIZAR)',
        text: 'Evitan que haya fuga de lubricante hacia el exterior por algún pequeño defecto en el mecanizado y de las zonas de cierre u otros mecanismos que tengan presión interna.',
      },
    ],
  },

  {
    name: 'PARTES ELÉCTRICAS',
    categoryName: 'partesElectricas',
    categoryList: [
      {
        id: 'SOLENOIDES',
        img: solenoidesSubCat,
        name: 'SOLENOIDES',
        text: 'El solenoide es cualquier dispositivo físico capaz de  crear un campo magnético sumamente uniforme e intenso en su interior y muy débil en su exterior.',
      },
      {
        id: 'MOTOR DE ARRANQUE',
        img: motorDeArranque,
        name: 'MOTOR DE ARRANQUE',
        text: 'Un motor de arranque es un dispositivo que se utiliza para girar un motor de combustión interna para iniciar el funcionamiento de este por su propia potencia.',
      },
      {
        id: 'ALTERNADORES',
        img: alternadores,
        name: 'ALTERNADORES',
        text: 'Pieza eléctrica capaz de transformar energía eléctrica generando una corriente alterna mediante inducción electromagnética.',
      },
      {
        id: 'SENSORES',
        img: sensores,
        name: 'SENSORES',
        text: 'Dispositivos capacitados para detectar actitudes o estímulos externos y responder en consecuencia. Los sensores pueden transformar las actitudes físicas o químicas en magnitudes eléctricas.',
      },
      {
        id: 'INTERRUPTORES',
        img: interruptoresSubCat,
        name: 'INTERRUPTORES',
        text: 'Esta pieza se encarga de habilitar la posibilidad de encendido y el apagado de un sistema, cuando el circuito eléctrico está abierto, la corriente puede pasar. Pero en el momento en que se cierra, esta deja de funcionar.',
      },
      {
        id: 'ALARMA DE RETROCESO',
        img: alarmaDeRetroceso,
        name: 'ALARMA DE RETROCESO',
        text: 'La alarma de retroceso es un elemento indispensable en entornos de trabajo de alto riesgo.',
      },
      {
        id: 'FAROS DE ILUMINACION',
        img: faroDeIluminacion,
        name: 'FAROS DE ILUMINACIÓN',
        text: 'Dispositivos que tienen como principal función la proyección de luz  sobre el terreno. Se encargan de iluminar la calzada lo mejor posible para una mayor seguridad, en especial durante la noche y en situaciones de baja visibilidad debido a las inclemencias del tiempo.',
      },
    ],
  },

  {
    name: 'INDICADORES',
    categoryName: 'indicadores',
    categoryList: [
      {
        id: 'INDICADOR PRESION DE AGUA',
        img: indicadorTemperaturaDeAgua,
        name: 'INDICADOR PRESIÓN DE AGUA',
        text: 'Un manómetro es un indicador analógico para medir la presión de un gas o líquido.',
      },
      {
        id: 'INDICADOR TEMPERATURA DE CONVERTIDOR',
        img: indicadorTemperaturaDeConvertidor,
        name: 'INDICADOR TEMPERATURA DE CONVERTIDOR',
        text: 'Indicador de temperatura de aceite convertidor para tractores Caterpillar',
      },
      {
        id: 'INDICADOR PRESION DE COMBUSTIBLE',
        img: indicadorPresionDeCombustible,
        name: 'INDICADOR PRESIÓN DE COMBUSTIBLE',
        text: 'Este dispositivo sirve para medir la operación de la bomba de combustible e indica la condición del conducto de combustible desde el tanque hasta su utilización.',
      },
      {
        id: 'INDICADOR PRESION AIRE',
        img: indicadorPresionDeAire,
        name: 'INDICADOR PRESIÓN AIRE',
        text: 'Pieza que muestra principalmente cuando la presión de aire es baja y fácilmente identificable en la mayoría de los vehículos',
      },
    ],
  },
  {
    name: 'SISTEMA DE INYECCIÓN',
    categoryName: 'sistemaDeInyeccion',
    categoryList: [
      {
        id: 'TOBERA TIPO LAPIZ',
        img: toberaTipoLapiz,
        name: 'TOBERA TIPO LAPIZ',
        text: 'Permite que sus piezas funcionales estén lejos del calor de la cámara de combustión, debido a su forma de tipo largo y delgado, en comparación con la mayor parte de los otros de su clase.',
      },
      {
        id: 'TOBERA',
        img: toberas,
        name: 'TOBERA',
        text: 'Una tobera es un dispositivo que convierte la energía térmica y de presión de un fluido en energía cinética.',
      },
      {
        id: 'BOMBANTES DE INYECCION',
        img: bombantes,
        name: 'BOMBANTES DE INYECCIÓN',
        text: 'Es un dispositivo capaz de elevar la presión de un fluido como es la gasolina.',
      },
      {
        id: 'TUBOS DE INYECCIÓN',
        img: tuboDeInyeccion,
        name: 'TUBOS DE INYECCIÓN',
        text: 'El objetivo de los tubos de inyección es llevar el combustible desde la bomba hasta los inyectores de combustible del motor de combustión.',
      },
    ],
  },
];

export default productsCategory;
