import S from './BannerImg.module.scss';
import { bannerImg } from 'infrastructure/assets/data';
import { useParams } from 'react-router-dom';

const BannerImg = () => {
  const { category } = useParams();
  const banner = bannerImg.find(({ name }) => name === category);

  return <img className={S.BannerImg} src={banner?.img} alt='products banner' />;
};

export default BannerImg;
