import axios from 'infrastructure/api/axios';
import { useEffect, useState } from 'react';
import { FormValuesProps, FormErrorsProps } from '../interfaces/interfaces';
import validation from './validation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useForm = (initValues: FormValuesProps, error: FormErrorsProps) => {
  const MySwal = withReactContent(Swal);

  //inputs state
  const [values, setValues] = useState({
    ...initValues,
  });

  //inputs errors
  const [errors, setErrors] = useState({
    ...error,
  });

  //validation reset
  const [isValidating, setIsValidating] = useState(false);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const data = {
        type: 'contact',
        ...values,
      };
      const response = await axios.post('/contact', data);

      if (response) {
        MySwal.fire({
          toast: true,
          icon: 'success',
          title: 'Gracias por contactarnos',
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error, por favor intenta de nuevo',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#132142',
      });
    } finally {
      setIsValidating(false);
      setValues({ ...initValues });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: type === 'checkbox' ? checked : value };
    });
  };

  useEffect(() => {
    setErrors(isValidating ? validation(values) : { ...error });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setDisableSubmit(isValidating ? Object.values(errors).some((error) => error !== '') : true);
  }, [errors, isValidating]);

  return { values, errors, disableSubmit, isValidating, handleSubmit, handleChange };
};

export default useForm;
