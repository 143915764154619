import S from './ProductCategoryTitle.module.scss';

interface productCategoryTitleProps {
  text: string;
}

const ProductCategoryTitle = ({ text }: productCategoryTitleProps) => {
  return (
    <div className={S.productCategoryTitle}>
      <h2 className={S.productCategoryTitle__title}>{text}</h2>
      <div className={S.productCategoriTitle__line} />
    </div>
  );
};

export default ProductCategoryTitle;
