import S from './NavLink.module.scss';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import { useLocation } from 'react-router-dom';

interface NavLinkProps {
  href: string;
  text: string;
  routing?: boolean;
  showDropdown?: boolean;
}

const NavLink = ({ href, text, routing, showDropdown }: NavLinkProps) => {
  const { redirecting } = useGoToRouteWithAnimation();
  const { pathname } = useLocation();

  return (
    <>
      {routing ? (
        <li className={S.linkCont}>
          <div onClick={redirecting(href, { from: pathname, to: href })} className={S.link}>
            <span>{text}</span>
          </div>
        </li>
      ) : (
        <li className={S.linkCont}>
          <a href={href} className={S.link}>
            <span>{text}</span>
          </a>

          {showDropdown && (
            <div
              className={S.dropdownCont}
              onClick={redirecting('/products', { from: pathname, to: '/products' })}
            >
              Ver productos
            </div>
          )}
        </li>
      )}
    </>
  );
};

export default NavLink;
