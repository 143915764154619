import { MainHeader } from 'infrastructure/components';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  return <>{location.pathname === '/' ? <MainHeader /> : <MainHeader outHome />}</>;
};

export default Header;
