import S from './FAQForm.module.scss';
import { FormInput, RoundButton, FormTextArea } from 'infrastructure/components';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import { useForm } from './hooks';

const FAQForm = () => {
  const { values, errors, disableSubmit, handleSubmit, handleChange } = useForm(
    {
      name: '',
      lastName: '',
      email: '',
      contactNumber: '',
      message: '',
    },
    {
      name: '',
      lastName: '',
      email: '',
      message: '',
    }
  );
  const { redirecting } = useGoToRouteWithAnimation();

  return (
    <form className={S.form} onSubmit={handleSubmit}>
      <FormInput
        id='FAQform-name'
        name='name'
        type='text'
        label='NOMBRE'
        value={values.name}
        errorMessage={errors.name}
        onChange={handleChange}
        isLabelSmall
        isInFAQ
        required
      />
      <FormInput
        id='FAQform-lastName'
        name='lastName'
        type='text'
        label='APELLIDO'
        value={values.lastName}
        errorMessage={errors.lastName}
        onChange={handleChange}
        isLabelSmall
        isInFAQ
        required
      />
      <FormInput
        id='FAQform-email'
        name='email'
        type='email'
        label='CORREO'
        value={values.email}
        errorMessage={errors.email}
        onChange={handleChange}
        isLabelSmall
        isInFAQ
        required
      />
      <FormInput
        id='FAQform-contactNumber'
        name='contactNumber'
        type='number'
        label='TELEFONO'
        value={values.contactNumber === null ? '' : values.contactNumber}
        isLabelSmall
        isInFAQ
        onChange={handleChange}
      />
      <FormTextArea
        id='FAQform-message'
        name='message'
        label='MENSAJE'
        value={values.message}
        errorMessage={errors.message}
        onChange={handleChange}
        isLabelSmall
        isInFAQ
        required
      />
      <div className={S.buttonsCont}>
        <span className={S.buttonOut} onClick={redirecting('/', { from: '/', to: '/faq' })}>
          SALIR
        </span>
        <RoundButton text='ENVIAR' small isInFAQ disabled={disableSubmit} />
      </div>
    </form>
  );
};

export default FAQForm;
