import S from './RoundButton.module.scss';
import { AttentionSeeker } from 'react-awesome-reveal';

interface RoundButtonProps {
  text: string;
  small?: boolean;
  disabled?: boolean;
  dark?: boolean;
  shaked?: boolean;
  isInFAQ?: boolean;
}

const RoundButton = ({ text, small, disabled, dark, shaked, isInFAQ }: RoundButtonProps) => {
  let smallBtn = small ? `${S.small}` : '';
  let darkTheme = dark ? `${S.dark}` : '';
  let nonShaked = !shaked ? `${S.nonShaked}` : '';
  let inFAQ = isInFAQ ? `${S.inFAQ}` : '';

  return (
    <>
      {shaked ? (
        <AttentionSeeker effect='headShake'>
          <button
            className={`${S.roundButton} ${smallBtn} ${darkTheme} ${inFAQ}`}
            disabled={disabled}
          >
            {text}
          </button>
        </AttentionSeeker>
      ) : (
        <button
          className={`${S.roundButton} ${smallBtn} ${darkTheme} ${nonShaked} ${inFAQ}`}
          disabled={disabled}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default RoundButton;
