import S from './BeginButton.module.scss';

interface BeginButtonProps {
  text: string;
}

const BeginButton = ({ text }: BeginButtonProps) => {
  return (
    <div
      className={S.cont}
      data-cursor-stick='#stick-title'
      data-cursor-color='transparent'
      tabIndex={-1}
    >
      <a href='#products' id='stick-title'>
        <button className={S.beginBtn}>{text}</button>
      </a>
      <div className={S.vertilcalLine}></div>
    </div>
  );
};

export default BeginButton;
