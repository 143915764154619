import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

type products = {
  name: string;
  categoryName: string;
  categoryList: {
    id: string;
    img: string;
    name: string;
    text: string;
  }[];
};

interface useFilterProductsByCategory {
  productsCategoryData: products[];
}

const useFilterProductByCategory = ({ productsCategoryData }: useFilterProductsByCategory) => {
  const { category } = useParams();
  const [categoryName, setCategoryName] = useState<string>('');
  const [subCategoryList, setSubCategoryList] = useState<
    { id: string; name: string; text: string; img: string }[]
  >([]);

  useEffect(() => {
    if (category === undefined) {
      return;
    } else {
      const productsFiltered = productsCategoryData.find(
        (product) => product.categoryName === category
      );
      if (productsFiltered !== undefined) {
        setCategoryName(productsFiltered.name);
        setSubCategoryList(productsFiltered.categoryList);
      }
    }
  }, [category, productsCategoryData]);

  return { categoryName, subCategoryList };
};

export default useFilterProductByCategory;
