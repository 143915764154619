import S from './Slider.module.scss';
import { useEffect, useState } from 'react';
import { SliderInfo, SliderImgSet, SliderButtonsSet } from 'infrastructure/components';

interface SliderProps {
  images: {
    id: number;
    img: string;
    title: string;
    text?: string;
  }[];
}

const Slider = ({ images }: SliderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const last = images.length - 1;
    if (activeIndex < 0) {
      setActiveIndex(last);
    }
    if (activeIndex > last) {
      setActiveIndex(0);
    }
  }, [images, activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex + 1);
    }, 4000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <section className={`${S.sliderSection}`} id='slider'>
      <div className={`${S.withBlueBgCont}`}>
        <div className={`${S.ImgCont}`}>
          <SliderInfo />
          <SliderImgSet images={images} activeIndex={activeIndex} />
        </div>
      </div>
      <SliderButtonsSet images={images} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
    </section>
  );
};

export default Slider;
