import { BannerImg, BannerInfo } from 'infrastructure/components';
import S from './ProductBanner.module.scss';

const ProductBanner = () => {
  return (
    <div className={S.productBanner}>
      <BannerImg />
      <BannerInfo />
    </div>
  );
};

export default ProductBanner;
