import S from './ViewProductsButton.module.scss';

interface viewProductsButtonProps {
  onClick: () => void;
  text?: string;
}

const ViewProductsButton = ({ onClick, text }: viewProductsButtonProps) => {
  return (
    <div className={S.viewProductsButton} onClick={onClick}>
      {text}
    </div>
  );
};

export default ViewProductsButton;
