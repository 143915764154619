import S from './SliderButtonsSet.module.scss';
import { SliderButton } from 'infrastructure/components';

interface SliderImgSetProps {
  images: {
    img: string;
    title: string;
  }[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  isInFAQ?: boolean;
}
const SliderButtonsSet = ({ images, activeIndex, setActiveIndex, isInFAQ }: SliderImgSetProps) => {
  return (
    <div className={S.sliderButtonsCont}>
      {images.map((image, index) => {
        let activeBtn = isInFAQ
          ? index <= activeIndex
            ? `${S.activeBtn}`
            : ``
          : index === activeIndex
          ? `${S.activeBtn}`
          : ``;
        return (
          <SliderButton
            key={index}
            onClick={() => setActiveIndex(index)}
            activeBtn={activeBtn}
            isInFAQ={isInFAQ}
          />
        );
      })}
    </div>
  );
};

export default SliderButtonsSet;
