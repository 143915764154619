import { useEffect, useState } from 'react';
import S from './FormTextArea.module.scss';

interface IFormTextArea {
  id: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  label: string | [JSX.Element, JSX.Element];
  value?: string | number;
  errorMessage: string;
  isLabelSmall?: boolean;
  isInFAQ?: boolean;
  isValidating?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

const FormTextArea = (props: IFormTextArea) => {
  const { label, errorMessage, isLabelSmall, isInFAQ, isValidating, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true);
  };
  const handleFocusedFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true);
  };

  useEffect(() => {
    if (!isValidating) {
      setFocused(false);
    }
  }, [isValidating]);

  return (
    <div className={`${S.formTextArea} ${isInFAQ && S.FAQ}`}>
      <label className={`${S.formTextArea__label} ${isLabelSmall && S.smallLabel}`}>{label}</label>
      <textarea
        className={`${S.formTextArea__textArea}`}
        {...inputProps}
        onBlur={handleFocusedBlur}
        onFocus={handleFocusedFocus}
        data-focused={errorMessage && focused}
      />

      <span className={`${S.formTextArea__span}`}>{errorMessage}</span>
    </div>
  );
};

export default FormTextArea;
