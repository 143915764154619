import S from './CardDescription.module.scss';

interface CardDescriptionProps {
  text: string;
}

const CardDescription = ({ text }: CardDescriptionProps) => {
  return <p className={S.description}>{text}</p>;
};

export default CardDescription;
