import {
  electricas,
  inyeccion,
  empacaduras,
  motor,
  indicadores,
  motorCategory,
  juegosDeEmpacaduraCategory,
  partesElectricasCategory,
  inyeccionCategory,
  indicadoresCategory,
} from 'infrastructure/assets/img';

const products = [
  {
    id: 1,
    name: 'componentes de motor',
    categoryName: 'componentesDeMotor',
    to: '/products/componentesDeMotor',
    img: motorCategory,
    text: 'Son los diferentes dispositivos que al recibir una señal, proceden a efectuar cualquier operación para lo que fueron diseñados.',
    icon: motor,
  },
  {
    id: 2,
    name: 'juegos de empacadura',
    categoryName: 'juegosDeEmpacadura',
    to: '/products/juegosDeEmpacadura',
    img: juegosDeEmpacaduraCategory,
    text: 'Las empacaduras son componentes de material adaptable que sirven para sellar bien la unión de dos piezas mecanizadas, generalmente presentes en cualquier elemento hidráulico y/o neumático, que lleven lubricante en su interior.',
    icon: empacaduras,
  },
  {
    id: 3,
    name: 'partes eléctricas',
    categoryName: 'partesElectricas',
    to: '/products/partesElectricas',
    img: partesElectricasCategory,
    text: 'El sistema eléctrico de un tractor o de un equipo automotor, es un conjunto de dispositivos relacionados entre sí, es el responsable del arranque del motor para poner en marcha la maquinaria.',
    icon: electricas,
  },
  {
    id: 4,
    name: 'indicadores',
    categoryName: 'indicadores',
    to: '/products/indicadores',
    img: indicadoresCategory,
    text: 'Piezas el cual su uso principalmente se utilizan para medir los diferentes componentes y estados de la maquinaria.',
    icon: indicadores,
  },
  {
    id: 5,
    name: 'sistema de inyección',
    categoryName: 'sistemaDeInyeccion',
    to: '/products/sistemaDeInyeccion',
    img: inyeccionCategory,
    text: 'Los sistemas de inyección son los mecanismo de alimentación de los motores de la maquinaria motores de combustión interna, en los que el carburador ha sido sustituido por los inyectores. En coches diésel es obligatorio, porque el combustible se inyecta en la cámara en el mismo momento de la combustión.',
    icon: inyeccion,
  },
];

export default products;
