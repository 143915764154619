import { agriculture, construction, pickaxe, maritime } from 'infrastructure/assets/img';

const industries = [
  {
    id: 1,
    img: construction,
    title: 'Construcción',
    description:
      'Suministramos piezas para vehículos industriales como: grúas, excavadoras, etc., utilizados en proyectos de carreteras y obras civiles.',
  },
  {
    id: 2,
    img: pickaxe,
    title: 'Minería',
    description:
      'Nos encargamos de abastecer el mecanismo de las diferentes maquinarias en el sector minero y la extracción de recursos naturales.',
  },
  {
    id: 3,
    img: agriculture,
    title: 'Agricultura',
    description:
      'La industria en la cual nos desenvolvemos con frecuencia es la agrícola, proporcionando piezas indispensables para la realización de actividades.',
  },
  {
    id: 4,
    img: maritime,
    title: 'Marítimo',
    description:
      'Ofrecemos piezas fundamentales que requieren los diferentes transportes marítimos a nivel regional y nacional.',
  },
];

export default industries;
