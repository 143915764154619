import S from './Subtitle.module.scss';
import { Fade } from 'react-awesome-reveal';

interface SubtitleProps {
  title: string;
  align: 'left' | 'center' | 'right';
  textColor: 'yellow' | 'blue' | 'white';
  textSize?: 'small' | 'medium' | 'large';
  lineColor: 'blue' | 'yellow';
  fontWeight: 'light' | 'normal' | 'bold';
}

const Subtitle = ({ title, align, lineColor, textColor, textSize, fontWeight }: SubtitleProps) => {
  let aligning = align === 'right' ? S.right : align === 'center' ? S.center : S.left;
  let lineColoring = lineColor === 'yellow' ? S.yellowLine : S.blueLine;
  let textColoring =
    textColor === 'yellow' ? S.yellowText : textColor === 'blue' ? S.blueText : S.whiteText;
  let textSizing =
    textSize === 'small' ? S.smallText : textSize === 'medium' ? S.mediumText : S.largeText;
  let fontWeighting = fontWeight === 'light' ? S.light : fontWeight === 'bold' ? S.bold : S.normal;
  let textAlign = align === 'right' ? S.textRight : align === 'center' ? S.textCenter : S.textLeft;

  return (
    <div className={`${S.cont} ${aligning}`}>
      <Fade cascade damping={0.3} triggerOnce direction='down'>
        <h2 className={`${S.title} ${textColoring} ${fontWeighting} ${textAlign} ${textSizing}`}>
          {title}
        </h2>
        <div className={`${S.line} ${lineColoring}`}></div>
      </Fade>
    </div>
  );
};

export default Subtitle;
