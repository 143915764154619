import S from './Products.module.scss';
import { Fade } from 'react-awesome-reveal';
import { Subtitle, RoundButton, ProductImgSet } from 'infrastructure/components';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import { productsData } from 'infrastructure/assets/data';

const Products = () => {
  const { redirecting } = useGoToRouteWithAnimation();

  return (
    <section className={S.Products} id='products'>
      <Fade triggerOnce>
        <p className={S.text}>¡EN TRACTOVAR CONTAMOS CON MÁS DE 5000 PRODUCTOS!</p>
      </Fade>
      <Subtitle
        title='¿Qué estás buscando?'
        align='center'
        lineColor='yellow'
        textColor='white'
        fontWeight='light'
      />
      <ProductImgSet productsData={productsData} isInHome />

      <div onClick={redirecting('/products', { from: '/', to: '/products' })}>
        <RoundButton text='VER TODOS LOS PRODUCTOS' shaked />
      </div>
    </section>
  );
};

export default Products;
