import S from './SliderButton.module.scss';

interface SliderImgProps {
  onClick: () => void;
  activeBtn: string;
  isInFAQ?: boolean;
}

const SliderButton = ({ onClick, activeBtn, isInFAQ }: SliderImgProps) => {
  let faqClass = isInFAQ ? `${S.faq}` : '';

  return (
    <button className={`${S.sliderButton} ${activeBtn} ${faqClass}`} onClick={onClick}></button>
  );
};

export default SliderButton;
