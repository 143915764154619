import { AnimationRouteIcon } from 'infrastructure/components';
import { useGlobalContext } from 'context';
import S from './RouteAnimationLoader.module.scss';
//import { useLocation } from 'react-router-dom';

const RouteAnimationLoader = () => {
  const { toLocalStorage } = useGlobalContext();

  // type LocationProps = {
  //   state: {
  //     from: string;
  //     to: string;
  //   };
  // };
  // const {
  //   state: { from, to },
  // } = useLocation() as unknown as LocationProps;
  //will use this for conditional rendering
  //const path = window.location.pathname;

  if (toLocalStorage.routerAnimation.fadeIn) {
    return (
      <>
        <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
          <AnimationRouteIcon />
        </div>
        {/*  
        {from === '/' && to === '/products' ? (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
            <AnimationRouteGif />
          </div>
        ) : from === '/products' && to === '/' ? (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
            <AnimationRouteGif />
          </div>
        ) : from === '/' && to === '/products/:id' ? (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
            <AnimationRouteGif />
          </div>
        ) : from === '/products/:id' && to === '/' ? (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
            <AnimationRouteGif />
          </div>
        ) : from === '/' && to === '/faq' ? (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
            <AnimationRouteGif />
          </div>
        ) : from === '/faq' && to === '/' ? (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>
            <AnimationRouteGif />
          </div>
        ) : (
          <div className={`${S.routeAnimationLoader} ${S.fadeIn}`}>SOY UN PRUEBA</div>
        )}
        */}
      </>
    );
  } else {
    return (
      <>
        {!toLocalStorage.routerAnimation.fadeOut && (
          <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
            <AnimationRouteIcon />
          </div>
        )}
        {/*  
        {from === '/' && to === '/products' ? (
          <>
            {!toLocalStorage.routerAnimation.fadeOut && (
              <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
                <AnimationRouteGif />
              </div>
            )}
          </>
        ) : from === '/products' && to === '/' ? (
          <>
            {!toLocalStorage.routerAnimation.fadeOut && (
              <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
                <AnimationRouteGif />
              </div>
            )}
          </>
        ) : from === '/' && to === '/products/:id' ? (
          <>
            {!toLocalStorage.routerAnimation.fadeOut && (
              <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
                <AnimationRouteGif />
              </div>
            )}
          </>
        ) : from === '/products/:id' && to === '/' ? (
          <>
            {!toLocalStorage.routerAnimation.fadeOut && (
              <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
                <AnimationRouteGif />
              </div>
            )}
          </>
        ) : from === '/' && to === '/faq' ? (
          <>
            {!toLocalStorage.routerAnimation.fadeOut && (
              <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
                <AnimationRouteGif />
              </div>
            )}
          </>
        ) : from === '/faq' && to === '/' ? (
          <>
            {!toLocalStorage.routerAnimation.fadeOut && (
              <div className={`${S.routeAnimationLoader} ${S.fadeOut} `}>
                <AnimationRouteGif />
              </div>
            )}
          </>
        ) : null}
        */}
      </>
    );
  }
};

export default RouteAnimationLoader;
