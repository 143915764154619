import S from './Navbar.module.scss';
import { NavLink } from 'infrastructure/components';

interface NavbarProps {
  inFooter?: boolean;
  outHome?: boolean;
}

const Navbar = ({ inFooter, outHome }: NavbarProps) => {
  let isInFooter = inFooter ? S.isInFooter : '';
  let NavisInFooter = inFooter ? S.navIsInFooter : '';

  return (
    <nav className={NavisInFooter}>
      <ul className={`${S.navList} ${isInFooter}`}>
        <NavLink href={outHome ? '/#slider' : '#slider'} text='INICIO' routing={outHome && true} />
        <NavLink
          href={outHome ? '/#whoAreWe' : '#whoAreWe'}
          text='QUIENES SOMOS'
          routing={outHome && true}
        />
        <NavLink
          href={outHome ? '/#industries' : '#industries'}
          text='INDUSTRIAS'
          routing={outHome && true}
        />
        <NavLink
          href={outHome ? '/#products' : '#products'}
          text='PRODUCTOS'
          routing={outHome && true}
          //showDropdown
        />
        <NavLink
          href={outHome ? '/#support' : '#support'}
          text='SOPORTE'
          routing={outHome && true}
        />
        <NavLink
          href={outHome ? '/#contact' : '#contact'}
          text='CONTÁCTANOS'
          routing={outHome && true}
        />
      </ul>
    </nav>
  );
};

export default Navbar;
