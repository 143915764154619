import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import S from './ProductsImg.module.scss';

interface ProductsImgProps {
  name: string;
  to: string;
  img: string;
}

const ProductsImg = ({ name, to, img }: ProductsImgProps) => {
  const { redirecting } = useGoToRouteWithAnimation();

  return (
    <div className={S.cont} onClick={redirecting(to, { from: '/', to })}>
      <img src={img} alt={name} className={S.productsImg} />
      <div className={S.name}>{name}</div>
    </div>
  );
};

export default ProductsImg;
