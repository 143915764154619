import { Suspense, useLayoutEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import * as Screen from '../screens';
import { ProductSubCategoryCardSet } from 'infrastructure/components';

const AppAllRoutes = () => {
  const { pathname, hash, key } = useLocation();

  useLayoutEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/' element={<Screen.Home />} />

        <Route path='/products' element={<Screen.Products />}>
          <Route path=':category' element={<ProductSubCategoryCardSet />} />
        </Route>

        <Route path='/support' element={<Screen.FAQ />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppAllRoutes;
