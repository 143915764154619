import S from './SocialLinkIcon.module.scss';

interface SocialLinkIconProps {
  src: string;
  text?: string;
  link: string;
  isInDrawer?: boolean;
}

const SocialLinkIcon = ({ src, text, link, isInDrawer }: SocialLinkIconProps) => {
  let inDrawer = isInDrawer ? `${S.inDrawer}` : '';

  return (
    <a className={`${S.socialLinkIcon} ${inDrawer}`} href={link} target='_blank' rel='noreferrer'>
      <img className={S.socialLinkIcon__icon} src={src} alt={text} title={text} />
      <span className={S.socialLinkIcon__text}>{text}</span>
    </a>
  );
};

export default SocialLinkIcon;
