import { portal, info, support } from 'infrastructure/assets/img';

const FAQslider = [
  {
    id: 1,
    img: portal,
    title: 'Este es el portal de atención al usuario.',
  },
  {
    id: 2,
    img: info,
    title: '¡Tu información es totalmente privada!',
  },
  {
    id: 3,
    img: support,
    title: 'Soporte se comunicará con usted en la brevedad posible.',
  },
];

export default FAQslider;
