import S from './HomeTemplate.module.scss';
import {
  Slider,
  WhoAreWe,
  Industries,
  Products,
  FAQ,
  Contact,
  Footer,
} from 'infrastructure/components';
import { sliderData } from 'infrastructure/assets/data';
import { useGoToRouteWithAnimation } from 'infrastructure/hooks';
import { useLayoutEffect } from 'react';
import { Cursor } from 'react-creative-cursor';
import 'react-creative-cursor/dist/styles.css';

const HomeTemplate = () => {
  const { reciever } = useGoToRouteWithAnimation();

  useLayoutEffect(() => {
    reciever();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={S.homeTemplateCont}>
      <Cursor
        isGelly={true}
        cursorInnerColor={'black'}
        cursorBackgrounColor='#E0B32D'
        cursorSize={18}
      />
      <div className={S.homeTemplate}>
        <main className={`${S.main}`}>
          <Slider images={sliderData} />
          <WhoAreWe />
          <Industries />
          <Products />
          <FAQ />
          <Contact />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default HomeTemplate;
