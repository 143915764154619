import S from './SliderInfo.module.scss';
import { BeginButton, PageTitle } from 'infrastructure/components';

const SliderInfo = () => {
  return (
    <div className={S.infoCont}>
      <PageTitle title='¡Tractovar te ofrece lo mejor para tu maquinaria!' />
      <BeginButton text='Empezar' />
    </div>
  );
};

export default SliderInfo;
