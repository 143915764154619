import axios from 'infrastructure/api/axios';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import S from '../ProductSubCategoryCardSet.module.scss';

interface FireAlertProps {
  categoryName: string;
  subCategoryName: string;
  infoText: string;
  img: string;
}

const useGetInTouchAlert = () => {
  const MySwal = withReactContent(Swal);

  const fireAlert = ({ categoryName, subCategoryName, infoText, img }: FireAlertProps) => {
    MySwal.fire({
      showConfirmButton: false,
      width: 'min(100%, 550px)',
      html: (
        <div className={S.popupCont}>
          <div>
            <img className={S.popupContentCont__img} src={img} alt='product' />
            <div className={S.popupContentCont__MtextCont}>
              <span className={S.popupContentCont__title}>{subCategoryName}</span>
              <p className={S.popupContentCont__text}>{infoText}</p>
            </div>
            <div className={S.popupLinkContD}>
              <div className={S.popupLinkCont__innerCont}>
                <span className={S.popupLinkCont__tile}>Contáctanos por:</span>
                <div className={S.popupLinkInnerCont}>
                  <a
                    href='https://wa.link/8cgubh'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => MySwal.close()}
                    className={`${S.popupLinkWhastapp}`}
                  >
                    <span className={S.popupLinkWhastappIcon}>
                      <FaWhatsapp />
                    </span>
                  </a>

                  <div onClick={MySwal.clickConfirm} className={`${S.popupLinkEmail}`}>
                    <span className={S.popupLinkEmailIcon}>
                      <AiOutlineMail />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={S.popupContentCont__DtextCont}>
            <span className={S.popupContentCont__title}>{subCategoryName}</span>
            <p className={S.popupContentCont__text}>{infoText}</p>
          </div>
          <div className={S.popupLinkContM}>
            <div className={S.popupLinkCont__innerCont}>
              <span className={S.popupLinkCont__tile}>Contáctanos por:</span>
              <div className={S.popupLinkInnerCont}>
                <a
                  href='https://wa.link/8cgubh'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => MySwal.close()}
                  className={`${S.popupLinkWhastapp}`}
                >
                  <span className={S.popupLinkWhastappIcon}>
                    <FaWhatsapp />
                  </span>
                </a>

                <div onClick={MySwal.clickConfirm} className={`${S.popupLinkEmail}`}>
                  <span className={S.popupLinkEmailIcon}>
                    <AiOutlineMail />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          html: (
            <form>
              <input
                type='text'
                id='alert-input-name'
                className={`swal2-input ${S.alertInputs}`}
                placeholder='Nombre'
              />
              <input
                type='text'
                id='alert-input-lastname'
                className={`swal2-input ${S.alertInputs}`}
                placeholder='Apellido'
              />
              <input
                type='email'
                id='alert-input-email'
                className={`swal2-input ${S.alertInputs}`}
                placeholder='Correo'
              />
              <textarea
                id='alert-textarea-msj'
                className={`swal2-textarea ${S.alertInputs} ${S.alertTextArea}`}
                placeholder='Dejanos tu duda'
              />
            </form>
          ),
          confirmButtonText: 'Enviar',
          confirmButtonColor: '#132142',
          focusConfirm: false,
          preConfirm: () => {
            //getting the inputs values
            const name: any =
              MySwal?.getPopup() !== null && MySwal?.getPopup()?.querySelector('#alert-input-name');

            const lastname: any =
              MySwal?.getPopup() !== null &&
              MySwal?.getPopup()?.querySelector('#alert-input-lastname');

            const email: any =
              MySwal?.getPopup() !== null &&
              MySwal?.getPopup()?.querySelector('#alert-input-email');

            const message: any =
              MySwal?.getPopup() !== null &&
              MySwal?.getPopup()?.querySelector('#alert-textarea-msj');

            //inputs validations
            if (!name.value.trim()) {
              MySwal.showValidationMessage('Por favor introduce tu nombre');
            } else if (
              name.value.trim() &&
              (name.value.trim().length < 3 || name.value.trim().length > 16)
            ) {
              MySwal.showValidationMessage('El campo nombre solo acepta de 3 a 16 caracteres!');
            } else if (name.value.trim() && !/^[a-zA-Z ]*$/.test(name.value.trim())) {
              MySwal.showValidationMessage(
                'El campo nombre no acepta numeros o caracteres especiales!'
              );
            } else if (!lastname.value.trim()) {
              MySwal.showValidationMessage('Por favor introduce tu apellido');
            } else if (
              lastname.value.trim() &&
              (lastname.value.trim().length < 3 || lastname.value.trim().length > 16)
            ) {
              MySwal.showValidationMessage('El campo apellido solo acepta de 3 a 16 caracteres!');
            } else if (lastname.value.trim() && !/^[a-zA-Z ]*$/.test(lastname.value.trim())) {
              MySwal.showValidationMessage(
                'El campo apellido no acepta numeros o caracteres especiales!'
              );
            } else if (!email.value) {
              MySwal.showValidationMessage(`Por favor introduce tu correo`);
            } else if (!email.value.trim()) {
              MySwal.showValidationMessage(`Por favor introduce tu correo`);
            } else if (
              email.value &&
              !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value.trim())
            ) {
              MySwal.showValidationMessage(`Por favor introduzca un email valido`);
            } else if (!message.value) {
              MySwal.showValidationMessage(`Por favor introduce tu mensaje`);
            } else if (!message.value.trim()) {
              MySwal.showValidationMessage(`Por favor introduce tu mensaje`);
            } else if (message.value.trim() && message.value.trim().length < 10) {
              MySwal.showValidationMessage(`El campo mensaje debe ser minimo 10 caracteres!`);
            } else {
              return {
                name: name.value,
                lastName: lastname.value,
                email: email.value,
                message: message.value,
              };
            }
          },
        }).then((result) => {
          if (result && result.isConfirmed && result.value) {
            const data = {
              type: 'question',
              categoryName,
              subCategoryName,
              ...result.value,
            };
            axios
              .post('/contact', data)
              .then((response) => {
                if (response) {
                  MySwal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Gracias por contactarnos',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              })
              .catch((error) => {
                MySwal.fire({
                  title: 'Error',
                  text: 'Ha ocurrido un error, por favor intenta de nuevo',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#132142',
                });
              });
          }
        });
      }
    });
  };

  return { fireAlert };
};

export default useGetInTouchAlert;
