import AppRouters from './infrastructure/routes/AppRouters';
import { useGlobalContext } from 'context';
import { RouteAnimationLoader } from 'infrastructure/components';
import { useEffect } from 'react';

const App = () => {
  const { toLocalStorage } = useGlobalContext();

  useEffect(() => {
    localStorage.setItem('tractovar', JSON.stringify(toLocalStorage));
  }, [toLocalStorage]);

  return (
    <>
      <RouteAnimationLoader />
      <AppRouters />
    </>
  );
};

export default App;
