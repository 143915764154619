import S from './GoogleMap.module.scss';

const GoogleMap = () => {
  return (
    <iframe
      className={S.googleMap}
      src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2777.5716747391793!2d-64.67216914050633!3d10.092569282711365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d6d7d9443ab85%3A0xea6acc5dbc4fb969!2sTRACTOVAR%20-%20(Inversiones%20Tractovar%20c.a.)!5e0!3m2!1ses!2sve!4v1660247518032!5m2!1ses!2sve'
      title='Tractovar Google Map'
      style={{ border: '0' }}
      allowFullScreen={false}
      loading='lazy'
      referrerPolicy='no-referrer'
    ></iframe>
  );
};

export default GoogleMap;
