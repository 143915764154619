import S from './ProductImgSet.module.scss';
import { ProductsImg } from 'infrastructure/components';
import { useEffect, useState } from 'react';

interface ProductImgSetProps {
  isInHome?: boolean;
  productsData: { id: number; name: string; to: string; icon: string }[];
}

const ProductImgSet = ({ productsData, isInHome }: ProductImgSetProps) => {
  const [products, setProducts] = useState<
    { id: number; name: string; to: string; icon: string }[]
  >([]);

  useEffect(() => {
    const filteredByScreen = isInHome ? productsData.slice(0, 5) : productsData;
    setProducts(filteredByScreen);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData]);

  return (
    <div className={S.productImgSet}>
      {products.map((product, index) => {
        return <ProductsImg key={index} name={product.name} to={product.to} img={product.icon} />;
      })}
    </div>
  );
};

export default ProductImgSet;
