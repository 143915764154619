import C from './constant';

export interface IInitialState {
  toLocalStorage: {
    routerAnimation: { fadeIn: boolean; fadeOut: boolean };
    redirect: boolean;
  };
}

export const initialState: IInitialState = {
  toLocalStorage: {
    routerAnimation: { fadeIn: false, fadeOut: false },
    redirect: false,
  },
};

const reducer = (state: IInitialState, { type, payload }: any) => {
  switch (type) {
    /*==============================*/

    case C.ROUTER_ANIMATION:
      return {
        ...state,
        toLocalStorage: {
          ...state.toLocalStorage,
          routerAnimation: { ...payload },
        },
      };
    /*==============================*/

    case C.REDIRECT:
      return {
        ...state,
        toLocalStorage: {
          ...state.toLocalStorage,
          redirect: payload,
        },
      };

    default:
      throw new Error('Unhandled action');
  }
};

export default reducer;
