import S from './IndustriesCard.module.scss';
import { CardImg, CardTitle, CardDescription } from 'infrastructure/components';
import { Fade } from 'react-awesome-reveal';

interface IndustriesCardProps {
  src: string;
  alt: string;
  title: string;
  text: string;
}

const IndustriesCard = ({ src, alt, title, text }: IndustriesCardProps) => {
  return (
    <div className={S.interest}>
      <Fade cascade damping={0.3} delay={800} triggerOnce>
        <CardImg src={src} alt={alt} />
        <CardTitle title={title} />
        <CardDescription text={text} />
      </Fade>
    </div>
  );
};

export default IndustriesCard;
