import S from './Contact.module.scss';
import { GoogleMap, Form } from 'infrastructure/components';

const Contact = () => {
  return (
    <section className={S.contact} id='contact'>
      <div className={S.contact__innerCont}>
        <div className={S.contact__mapCont}>
          <GoogleMap />
        </div>
        <div className={S.contact__formCont}>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default Contact;
