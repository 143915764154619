import S from './Card.module.scss';

interface CardTitleProps {
  title: string;
}

const CardTitle = ({ title }: CardTitleProps) => {
  return <h3 className={S.title}>{title}</h3>;
};

export default CardTitle;
