import S from './Description.module.scss';
import { Subtitle } from 'infrastructure/components';
import { Fade } from 'react-awesome-reveal';

const Description = () => {
  return (
    <div className={S.description}>
      <Subtitle
        title='¿Quienes somos?'
        align='left'
        lineColor='blue'
        textColor='blue'
        fontWeight='bold'
      />
      <Fade cascade delay={800} triggerOnce>
        <p className={S.description__text}>
          <span>Inversiones Tractovar</span> es una empresa dedicada a la comercialización de piezas
          importadas para maquinaria pesada del área de construcción y agrícola en Venezuela.
          Contamos con más de 26 años de experiencia en el mercado, siempre atentos a las nuevas
          tendencias y ofreciendo a nuestros clientes un amplio stock de repuestos y productos.
        </p>
      </Fade>
    </div>
  );
};

export default Description;
