import S from './BannerTitle.module.scss';

interface BannerTitleProps {
  text: string;
}
const BannerTitle = ({ text }: BannerTitleProps) => {
  return <h1 className={S.bannerTitle}>{text}</h1>;
};

export default BannerTitle;
